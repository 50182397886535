import React from 'react';
import { Route } from 'react-router';
import withTracker from './withTracker';
import AsyncComponent from './AsyncComponent';
const Home = AsyncComponent(() => import('../../Views/Home'));
const Secondary = AsyncComponent(() => import('../../Views/Secondary'));

const router = () => (
  <div className="route-content">
    <Route exact path='/' component={withTracker(Home)} />
    <Route exact path='/portal' component={withTracker(Home)} />
    <Route exact path='/portal/:page_name' component={withTracker(Secondary)} />
    <Route exact path='/portal/:page_name/:fetch_id' component={withTracker(Secondary)} />
  </div>
)
export default router