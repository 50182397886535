import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators as dbCreators } from '../store/db';
import { bindActionCreators } from 'redux';
import Navbar from '../../components/NavBar/Navbar';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HomePageData: props.HomePageData
    }
  }
  async componentDidMount() {
    const HomePageData = await this.handleLoadHomeData();
    this.setState({
      loading: false,
      HomePageData
    });
  }
  handleLoadHomeData = async () => {
    const { db } = this.props;
    const pageInfo = await db.t_page.load({
      expand: 't_page_layout.t_component',
      filter: ['page_name', '=', 'Home']
    });
    //Home Page has only one layout
    const components = pageInfo[0].t_page_layout[0].t_component.sort((a, b) => a.order - b.order);
    const HomePageData = await this.mapComponentsToData({ prop: 't_component', value: ['StandardGallery', 'NavBar', 'PictureList'] }, components);
    return HomePageData;
  }
  mapComponentsToData = async ({ prop, value }, components) => {
    let obj = {};
    const { db } = this.props;
    if (Array.isArray(value)) {
      obj[prop] = value.map(component_name => new db.Model.t_component(components.find((o => o.component_name === component_name))));
      for (var i = 0; i < obj[prop].length; i++) {
        const table = obj[prop][i].datasource.table;
        if (table) {
          const data = await db[table].load(obj[prop][i].datasource.loadoptions);
          obj[table] = data.map(o => new db.Model[table](o));
        }
      }
    }
    return obj;
  }
  render() {
    const { HomePageData } = this.state;
    const navMenuComponent = HomePageData.t_component.find((o => o.component_name === 'NavBar')) || { props: {} };
    return (
      <div className="header-sticky">
        <Navbar dataSource={HomePageData.t_navitem} logo={navMenuComponent.props.logo} style={navMenuComponent.props.style} />
      </div>
    );
  }
}
Header.defaultProps = {
  HomePageData: {
    t_component: [
      {
        component_name: 'NavBar',
        props: "{}",
        datasource: {}
      }
    ],
    t_navitem: []
  }
}

export default connect(
  state => ({ db: state.db }),
  dispatch => bindActionCreators({ ...dbCreators }, dispatch)
)(Header);