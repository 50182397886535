import React, { Component } from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';
import Typography from '@material-ui/core/Typography';

const style = {
  loadText: {
    fontWeight: 200,
    display: 'inline-block',
    whiteSpace: 'pre'
  },
  loadIcon: {
    fontSize: '50px',
    borderRadius: '50%',
    color: 'rgba(255,255,255,0.5)',
    padding: '10px',
    position: 'relative'
  }
};

class AppLoader extends Component {
  displayName = 'AppLoader';

  constructor(props){
    super(props);
    this.state = {
      ellipse: '.  ',
      loadText: this.props.loadText
    };
    this.loadElem = (
      this.props.icon ?
      <i style={style.loadIcon} className={`material-icons mi-${this.props.icon} ${this.props.color}`}>
        <LoadIndicator width={75} height={75} elementAttr={{class: 'absolute-center'}}/>
      </i>
      : <LoadIndicator width={50} height={50}/>
    )
  }
  componentDidMount(){
    let i = 0;
    this.interval = setInterval(()=>{
      let ellipseEnum = ['.  ','.. ','...'];
      this.setState({ ellipse: ellipseEnum[++i % 3]});
    }, 500);
  }
  componentWillUnmount(){
    clearInterval(this.interval);
  }
  render() {
    return (
      <div className={`absolute-center ${this.props.className}`} style={this.props.style}>
        {this.loadElem}
        <div style={style.loadTextContainer}>
          <Typography gutterBottom variant="h6" style={style.loadText}>{this.state.loadText}</Typography>
          <Typography gutterBottom variant="h6" style={style.loadText}>{this.state.ellipse}</Typography>
        </div>
      </div>
    );
  }
}
AppLoader.defaultProps={
  loadText: 'Loading',
  icon: null,
  className: '',
  style: {},
  color: 'indigo darken-4'
}
export default (AppLoader);