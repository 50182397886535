//import {userstore} from '../data/userstore';
//Layout Store editer
const dbupdate = 'DB_UPDATE';
const initialState = {};

export const actionCreators = {
  //title update action creator
  dbupdate: (db) => ({ type: dbupdate, db }),
};

export const reducer = (state, action) => {
  state = state || initialState;
  //The title Update function that sets a new title
  if (action.type === dbupdate) return action.db;
  return state;
};