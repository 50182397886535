import React from 'react';
import Layout from './afw/Layout/Layout';
import Router from './afw/Layout/Router';
import DataContext from './afw/Layout/DataContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import './afw/css/pace.css';
import './afw/css/common.css';
import './afw/css/layout.css';
import './afw/css/color.css';
import './afw/css/bootstrapcols.css';
import './afw/css/material-icons.css';
import './components/HTMLContent/HTMLContent.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import Header from './afw/Layout/Header';
import Footer from './components/Footer/Footer';
import { ParallaxProvider } from 'react-scroll-parallax';

const app = () => (
  <Layout>
    <CssBaseline />
    <DataContext>
      <div className="top-relative default-shadow">
        <Header />
        <ParallaxProvider>
          <Router />
        </ParallaxProvider>
        <Footer />
      </div>
    </DataContext>
  </Layout>
)
export default app