import React, { PureComponent } from 'react';
import List from '@material-ui/core/List';
import LinkDuo from '../LinkDuo/LinkDuo';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextBox from 'devextreme-react/text-box';
import { Typography } from '@material-ui/core';

const handleReduceResults = function(results){
  return results.reduce((total, currentValue, i, arr)=>{
    total.push(arr[i]);
    total = total.concat(arr[i].SR_t_navitem_PARENT_ID)
    return total;
  }, []).sort((a,b)=>{
    var textA = a.nav_title.toUpperCase();
    var textB = b.nav_title.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  })
}
const handleDistinct = function(arr){
  const result = [];
  const map = new Map();
  for (const item of arr) {
      if(!map.has(item.nav_title)){
          map.set(item.nav_title, true);    // set any value to Map
          result.push({
              nav_id: item.nav_id,
              nav_title: item.nav_title,
              nav_link: item.nav_link,
              target: item.target
          });
      }
  }
  return result;
}
class AZMenu extends PureComponent {
  state={searchResults: handleDistinct(handleReduceResults(this.props.navData))}
  handleFilter=(value)=>{
    value = value.toLowerCase();
    const searchResults = handleDistinct(handleReduceResults(this.props.navData).filter((o)=>{
      return o.nav_title.toLowerCase().indexOf(value) > -1 || o.nav_link.toLowerCase().indexOf(value) > -1
    }));
    this.setState({searchResults})
  }
  render() {
    const {searchResults} = this.state;
    return (
      <div className="navWrapper" >
        <TextBox 
          stylingMode="underlined"
          placeholder="Search" 
          mode="search"
          elementAttr={{ style: "margin: 5px" }} 
          valueChangeEvent="keyup"
          onValueChanged={(e)=>{
            this.handleFilter(e.value);
          }}
        />
        {
          (searchResults && searchResults.length &&
            <div className="navMain" >
              <List>
                {
                  searchResults.map(
                    (row) => (
                      <ListItem button component={LinkDuo} to={`${row.nav_link}`} target={row.target ? "_blank" : undefined}>
                        <ListItemText key={"lit" + row.nav_id} primary={<span style={{marginLeft: '20px'}}>{row.nav_title}</span>} />
                      </ListItem>
                    )
                  )
                }
              </List>
            </div>) ||
          <div className="absolute-center" style={{width: '100%'}}>
            <Typography variant="h4">No Results Found</Typography>
          </div>
        }
      </div>
    );
  }
}
AZMenu.defaultProps={
  navData: []
}
export default AZMenu;