import React, { PureComponent } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LinkDuo from '../LinkDuo/LinkDuo';

class SideMenuCategory extends PureComponent {
  state={open: false}
  handleNavToggle = () => {
    this.setState({open: !this.state.open});
  }
  render() {
    const {data} = this.props;
    const {open} = this.state;
    return (
      <div>
        {data.SR_t_navitem_PARENT_ID.length ? (
          <ListItem  button onClick={this.handleNavToggle}>
            <ListItemText key={"lit" + data.nav_id} primary={data.nav_title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : (
          <ListItem button component={LinkDuo} to={`${data.nav_link}`} target={data.target ? "_blank" : undefined}>
            <ListItemText key={"lit" + data.nav_id} primary={<span>{data.nav_title}</span>} />
          </ListItem>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          {
            data.SR_t_navitem_PARENT_ID.map(
              (row, i) => (
                <div key={row.nav_id}>
                  {i === 0 && data.nav_link && (
                    <ListItem button component={LinkDuo} to={`${data.nav_link}`} target={data.target ? "_blank" : undefined}>
                      <ListItemText key={"lit" + data.nav_id} primary={<span style={{marginLeft: '20px'}}>{data.nav_title}</span>} />
                    </ListItem>
                  )}
                  <ListItem button component={LinkDuo} to={`${row.nav_link}`} target={row.target ? "_blank" : undefined}>
                    <ListItemText key={"lit" + row.nav_id} primary={<span style={{marginLeft: '20px'}}>{row.nav_title}</span>} />
                  </ListItem>
                </div>
              )
            )
          }
        </Collapse>
      </div>
    );
  }
}
SideMenuCategory.defaultProps={
  data: {}
}
export default SideMenuCategory;