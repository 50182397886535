import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import './EditWrapper.css';

const styles = theme => ({
  relative: {
    position: 'relative'
  },
  placeTop: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: 10
  },
  inlineBlock:{
    display: 'inline-block'
  },
});
class EditWrapper extends PureComponent {
  render(){
    const {
      children, 
      removeEnabled, 
      editEnabled, 
      classes,
      onEdit,
      onRemove,
      editTooltip,
      removeTooltip
    } = this.props;
    return (
      <React.Fragment>
      {
          editEnabled || removeEnabled ? 
          <div className={`${editEnabled || removeEnabled ? 'component-edit-hover' : ""} ${classes.relative}`}>
            <div className={classes.placeTop}>
              <div className={classes.inlineBlock}>
                {
                  editEnabled ? 
                  <Tooltip title={editTooltip} >
                    <ButtonBase focusRipple className={"component-edit-button blue white-text"} onClick={onEdit}>
                      <Create />
                    </ButtonBase> 
                  </Tooltip>: null
                }
                {
                  removeEnabled ? 
                  <Tooltip title={removeTooltip} >
                    <ButtonBase focusRipple className={"component-remove-button red white-text"} onClick={onRemove}>
                      <Delete />
                    </ButtonBase> 
                  </Tooltip> : null
                }
              </div>
            </div>
            {children}
          </div> : <React.Fragment>{children}</React.Fragment>
      }
      </React.Fragment>
    )
  }
}
EditWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};
EditWrapper.defaultProps={
  removeEnabled: false,
  editEnabled: false,
  editTooltip: "Edit",
  removeTooltip: "Remove",
  onEdit: ()=>{},
  onRemove: ()=>{}
}

export default withStyles(styles)(EditWrapper);