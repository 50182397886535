import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import LinkDuo from '../LinkDuo/LinkDuo';
import History from '@material-ui/icons/History';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  historyRemove: {
    display: 'inline-block', 
    verticalAlign: 'middle', 
    padding: '9px', 
    marginTop: '3px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  historyItem: {
    width: '75%',
    display: 'inline-block', 
    verticalAlign: 'middle', 
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    zIndex: 1500,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  item: {
    width: '100%', 
    zIndex: 1500
  }
});

class DownShiftSuggestion extends Component {
  render() {
    const { data, index, highlightedIndex, itemProps, classes } = this.props;
    const isHighlighted = highlightedIndex === index;
    const isHistory = data.result_type === 'HISTORY';
    const className = isHistory ? classes.historyItem : classes.item
    return (
      <React.Fragment key={data.v_id}>
        <MenuItem
          selected={isHighlighted}
          component={LinkDuo}
          to={data.link}
          target={(data.result_type === 'FILE' || data.icon !== 'html') ? "_blank" : undefined}
          className={className}
          dense
          {...itemProps}
        >
          {isHistory
            ? <span>
                <History style={{verticalAlign: 'middle', marginRight: '10px'}}/>
                <span style={{verticalAlign: 'middle'}}>{data.name}</span>
              </span> 
            : <span>
                <span style={{verticalAlign: 'middle'}}>{data.name}</span>
              </span> 
          }
        </MenuItem>
        {
          isHistory && (
            <ButtonBase className={classes.historyRemove} onClick={()=>this.props.onHistoryRemoved(data)}>
              <Typography variant="body2" className="blue-text">Remove</Typography>
            </ButtonBase>
          )
        }
      </React.Fragment >
    )
  }
}
DownShiftSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(DownShiftSuggestion);