import React, { PureComponent } from 'react';
import List from '@material-ui/core/List';
import AppLoader from '../../afw/Layout/AppLoader';
import logo from '../../images/mdot_color_text.png';
import SideMenuCategory from './SideMenuCategory';

class SideMenu extends PureComponent {
  render() {
    return (
      <div className="navWrapper" >
        <div className={`navHeader white`} style={{height: 'auto'}}>
          <img alt="Navigation Logo" src={logo} style={{height: '65px'}}/>
        </div>
        {/* <SideMenuSearch navData={this.props.navData}/> */}
        {
          (this.props.navData && this.props.navData.length &&
            <div className="navMain" >
              <List>
                {
                  this.props.navData.map(
                    (row) => <SideMenuCategory key={row.nav_id} data={row}/>
                  )
                }
              </List>
            </div>) ||
          <div className="navMainLoad" style={{position: 'relative'}}>
            <AppLoader loadText="Navigation Loading" />
          </div>
        }
      </div>
    );
  }
}
SideMenu.defaultProps={
  navData: []
}
export default SideMenu;