import React, { Component } from "react";
import AppLoader from "./AppLoader";
import Pace from 'pace-js-amd-fix';
export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }
    async componentDidMount() {
      const { default: component } = await importComponent();
      Pace.restart();
      this.setState({
        component: component
      });
    }
    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : <AppLoader />;
    }
  }
  return AsyncComponent;
}