import update from 'immutability-helper';
import cloneDeep from 'lodash.clonedeep';
import { immerable } from 'immer';
class EntityModel {
    /* _ classes represent 'private' functions and properties. Do not touch. */
    _hasChanges = false;
    _original = ""
    _initialized = false;
    _mergeDefaultProps(props) {
        if (typeof props === 'object') {
            for (let prop in props)
                if (this[prop] !== undefined) this[prop] = props[prop];
        }
        if (this['imports'] && typeof this['imports'] === 'function') this['imports']();
        this._setOriginal();
    }
    _setOriginal() {
        const { _original, _hasChanges, _initialized, exports, ...newObj } = this;
        this._original = JSON.stringify(newObj);
        this._hasChanges = false;
        this._initialized = true;
    }
    _onPropertyChanged(name) {
        if (this._initialized && (name !== '_initialized' && name !== '_hasChanges' && name !== '_original')) {
            const { _original, _hasChanges, _initialized, exports, ...current } = this;
            this._hasChanges = this._original !== JSON.stringify(current);
        }
    }
    hasChanges() {
        return this._hasChanges
    }
    set(prop, value) {
        this[prop] = value;
        this._onPropertyChanged();
    }
    resetChanges() {
        this._setOriginal();
    }
    original() {
        return JSON.parse(this._original)
    }
    clone() {
        return cloneDeep(this)//Object.assign( Object.create( Object.getPrototypeOf(this)), this)
    }
    toJS(proto) {
        let jsoned = {};
        let toConvert = proto || this;
        const props = Object.getOwnPropertyNames(toConvert)
        props.forEach((prop) => {
            const val = toConvert[prop];
            // don't include those
            if (prop === 'toJS' || prop[0] === '_' || prop === 'constructor' || prop === 'displayName' || (typeof val === 'function')) {
                return;
            }
            jsoned[prop] = val;
        });
        if(typeof toConvert.exports === 'function'){
          jsoned = update(jsoned, {$merge: toConvert.exports(jsoned, toConvert.displayName)})
        }
        const inherited = Object.getPrototypeOf(toConvert);
        if (inherited !== null) {
            Object.keys(this.toJS(inherited)).forEach(key => {
                if (!!jsoned[key] || key === 'constructor' || key[0] === '_' || key === 'displayName' || key === 'toJS' || key === 'mergeDefaultProps')
                    return;
                if (typeof inherited[key] === 'function') {
                    jsoned[key] = inherited[key].bind(jsoned);
                    return;
                }
                jsoned[key] = inherited[key];
            });
        }
        return jsoned;
    }
}
EntityModel.prototype[immerable] = true;
export default EntityModel;