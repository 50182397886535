import ODataContext from 'devextreme/data/odata/context';
import parseObjectModel from './parseEntityModel';
import { url } from './db';
import { entities, OM } from './entities';

async function db(data) {
  let db = new ODataContext({
    url: url,
    version: 4,
    entities: {
      ...JSON.parse(entities), getUser: {}, displayProperties: {},
      showMethods: {
        beforeSend: (headers) => {
          headers.method = 'POST';
        }
      }
    }
  });
  db.Model = parseObjectModel(JSON.parse(OM));
  return db;
}
export default db;