/*
 * File: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp\src\components\LinkDuo\LinkDuo.js
 * Project: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp
 * Created Date: Wednesday July 10th 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Wednesday July 15th 2020 9:31:53 am
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React from 'react';
import { Link } from 'react-router-dom';
import isExternal from 'is-url-external';
/**
 * Link that also works for external URL's
 */
const LinkDuo =(props) => {
  return (isExternal(props.to) || (props.target === '_blank') || (props.to.indexOf('/portal/') === -1)) ?
  // eslint-disable-next-line
  <a
    href={props.to}
    rel="noopener"
    {...props}
  />
  :
  <Link {...props} target={undefined} />;
}
export default LinkDuo;