/*
 * File: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp\src\components\Footer\Footer.js
 * Project: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp
 * Created Date: Wednesday July 10th 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Thursday March 19th 2020 6:02:19 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { PureComponent } from 'react';
import Typography  from '@material-ui/core/Typography';
import facebook from '../../afw/icons/facebook.png'
import instagram from '../../afw/icons/instagram.png';
import twitter from '../../afw/icons/twitter.png';
import linkedin from '../../afw/icons/linkedin.png';
import youtube from '../../afw/icons/youtube.png';
import logo from '../../images/logo.png';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
//   Dark Blue  background: 'rgba(18,36,65, 1)',
import LazyLoad from 'react-lazyload';
import LinkDuo from '../LinkDuo/LinkDuo';
const styles = theme => ({
  container: {
    //minHeight: '200px',
    color: '#fff',
    width: '100%',
    background: 'rgb(51,51,51)'
  },
  containerBlue: {
    //minHeight: '200px',
    color: '#fff',
    width: '100%',
  },
  wrapper: {
    padding: '20px 20%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    },
  },
  iconWidth: {
    maxWidth: '32px'
  },
  smPadding: {
    padding: '8px'
  },
  shiftText: {
    marginLeft: '15px',
    display: 'block'
  },
  logoWidth: {
    maxWidth: '150px',
    marginBottom: '10px'
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '20px 0',
    textAlign: 'center',
    minWidth: '250px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    },
  },
  block: {
    display: 'block'
  }
})
class Footer extends PureComponent {
  render() {
    let {classes} = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.inlineBlock}>
            <LazyLoad height={100} once offset={100}>
              <IconButton component={LinkDuo} to={"https://www.facebook.com/MississippiDOT"} className={classes.smPadding} color="inherit">
                <img alt="facebook" src={facebook} className={classes.iconWidth}/>
              </IconButton>
              <IconButton component={LinkDuo}  to={"https://twitter.com/MississippiDOT"} className={classes.smPadding} color="inherit">
                <img alt="twitter" src={twitter} className={classes.iconWidth}/>
              </IconButton>
              <IconButton component={LinkDuo}  to={"https://www.instagram.com/mississippidot/"} className={classes.smPadding} color="inherit">
                <img alt="instagram" src={instagram} className={classes.iconWidth}/>
              </IconButton>
              <IconButton component={LinkDuo}  to={"https://www.youtube.com/user/MississippiDOT"} className={classes.smPadding} color="inherit">
                <img alt="youtube" src={youtube} className={classes.iconWidth}/>
              </IconButton>
              <IconButton component={LinkDuo}  to={"https://www.linkedin.com/company/mississippi-department-of-transportation"} className={classes.smPadding} color="inherit">
                <img alt="linkedin" src={linkedin} className={classes.iconWidth}/>
              </IconButton>
              <Typography className={classes.shiftText} variant="caption" color="inherit">
                Like • Follow • Subscribe
              </Typography>
              <Typography className={classes.shiftText} variant="caption" color="inherit">
                @MississippiDOT
              </Typography>
            </LazyLoad>
          </div>
          <div className={classes.inlineBlock}>
            <LazyLoad height={55} offset={100}>
              <img alt="MDOT" src={logo} className={classes.logoWidth}/>
            </LazyLoad>
            <Typography variant="caption" color="inherit" className={classes.block}>
              <LinkDuo to={"/portal/contacts"} style={{textDecoration: 'none'}}>
                <Typography variant="caption" className="white-text" style={{display: 'inline'}}>
                  Contact
                </Typography>
              </LinkDuo>&nbsp;|&nbsp; 
              <LinkDuo to={"/documents/Administration/privacy.pdf"} target="_blank" style={{textDecoration: 'none'}}>
                <Typography variant="caption" className="white-text" style={{display: 'inline'}}>
                  Privacy
                </Typography>
              </LinkDuo>&nbsp;|&nbsp; 
              <LinkDuo to={"/documents/Administration/TermsOfUse.pdf"} target="_blank" style={{textDecoration: 'none'}}>
                <Typography variant="caption" className="white-text" style={{display: 'inline'}}>
                  Terms of Use
                </Typography>
              </LinkDuo>&nbsp;|&nbsp; 
              <LinkDuo to={"http://www.transparency.mississippi.gov/"} target="_blank"  style={{textDecoration: 'none'}}>
                <Typography variant="caption" className="white-text" style={{display: 'inline'}}>
                  Transparency
                </Typography>
              </LinkDuo>&nbsp;|&nbsp; 
              <LinkDuo to={"/portal/civil_rights/#c1064"} style={{textDecoration: 'none'}}>
                <Typography variant="caption" className="white-text" style={{display: 'inline'}}>
                  EEO
                </Typography>
              </LinkDuo>
            </Typography>
          </div>
          <div className={classes.inlineBlock}>
            <Typography variant="caption" color="inherit" className={classes.block}>
              Mississippi Department of Transportation
            </Typography>
            <Typography variant="caption" color="inherit" className={classes.block}>
              401 North West Street
            </Typography>
            <Typography variant="caption" color="inherit" className={classes.block} gutterBottom>
              Jackson, MS 39201
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default (withStyles(styles)(Footer));