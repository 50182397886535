import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators as dbCreators } from '../store/db';
import { bindActionCreators } from 'redux';
import AppLoader from './AppLoader';
import db from '../../data/getDB';

class DataContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contextLoaded: false
    };
  }
  async componentDidMount(){
    this.props.dbupdate(await db())
    this.setState({contextLoaded: true});
  }
  render() {
    const {contextLoaded} = this.state;
    const {children} = this.props;
    return (
      <React.Fragment>
        {!contextLoaded ? <AppLoader loadText="App Loading" /> : children}
      </React.Fragment>
    );
  }
}
export default connect(
  state => ({db: state.db, objectModel: state.ObjectModel}),
  dispatch => bindActionCreators({...dbCreators}, dispatch)
)(DataContext);