import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import DownShiftFetch from './DownShiftFetch';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles';
import DownShiftSuggestion from './DownShiftSuggestion';
import LoadIndicator from 'devextreme-react/load-indicator';
import Typography from '@material-ui/core/Typography';
import LocalStore from 'devextreme/data/local_store';
import notify from 'devextreme/ui/notify';
import {withRouter} from 'react-router-dom';

export const historyStore = new LocalStore({
  name: "GOMDOT_search_history",
  key: "link",
  keyType: 'String'
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    marginRight: '16px',
    marginLeft: '24px',
    overflow: 'hidden'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.grey[600], 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[600], 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
});

class DownShiftSearch extends Component {
  state={inputValue: null}
  handleSelectChange=async (e)=>{
    try{
      // eslint-disable-next-line
      const result = await historyStore.byKey(e.link)
    }catch(error){
      await historyStore.insert({...e, result_type: 'HISTORY', added: new Date()});
    }
  }
  handleHistoryRemoved=async (e)=>{
    await historyStore.remove(e.link);
    notify(`${e.name} has been removed from search history`);
    this.dsf.prepareFetch()
  }
  handleClearInput=()=>{
    this.setState({inputValue: null})
  }
  handleInputChange=(e)=>{
    this.setState({inputValue: e.target.value === '' ? null : e.target.value})
  }
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <Downshift id="downshift-simple" aria-label="Search MDOT" inputValue={this.state.inputValue} onChange={this.handleSelectChange}>
          {({
            getMenuProps,
            highlightedIndex,
            isOpen,
            getInputProps,
            inputValue,
            getItemProps,
            openMenu
          }) => {
            const { onBlur, onFocus, onChange, ...inputProps } = getInputProps({
              placeholder: 'Search...',
              onKeyDown: event => {
                if (event.key === 'Enter') {
                  const data = this.preFilteredResults[highlightedIndex]
                  if(data.result_type === 'FILE' || data.icon !== 'html'){
                    window.open(data.link, "_blank");
                  }else{
                    this.props.history.push(data.link)
                  }
                }
              },
            });
            return (
              <div className={classes.container}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    defaultValue={inputValue}
                    onChange={event => {
                      this.handleInputChange(event);
                      onChange(event);
                    }}
                    inputProps={{onBlur, onFocus: openMenu, ...inputProps}}
                  />
                </div>

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      <DownShiftFetch
                        ref={(ref)=>this.dsf = ref}
                        searchValue={inputValue}
                        preFilteredResults={this.preFilteredResults}
                        onLoaded={({searchValue, fullResults})=>{
                          this.preFilteredResults = fullResults
                        }}
                        render={({loading, results, error})=>(
                          loading 
                            ? <div style={{padding: '20px', textAlign: 'center'}}><LoadIndicator /></div>
                            : results.length > 0 ? results.map((data, index)=>
                              <DownShiftSuggestion onHistoryRemoved={this.handleHistoryRemoved} itemProps={getItemProps({item: data })} loading={loading} data={data} error={error} highlightedIndex={highlightedIndex} index={index}/>
                            ) : <Typography variant="h5" style={{padding: '20px', textAlign: 'center'}}> No Results Found... </Typography>
                        )}
                      />
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    )
  }
}
DownShiftSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(DownShiftSearch));