import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Layout';
import { bindActionCreators } from 'redux';

class Layout extends Component {
  render() {
    return (
        <div style={{background: "#eaeaea"}}>
            {this.props.children}
        </div>
    );
  }
}
export default connect(
  state => state.layout,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Layout);