//TODO: Add common utility functions here
//export function methodName1() { return 'methodName1'; };
//Cancelable Promise Wrapper
import moment from 'moment';
import set from 'lodash.set';
import has from 'lodash.has';
import produce from 'immer';

export function smartDate(date) {
  let ddiff = moment().diff(date, 'days');
  let ydiff = moment().diff(date, 'years');
  if (ddiff <= 1) {
    return moment(date).format("hh:mm a")
  } else if (ydiff === 0) {
    return moment(date).format("MMM DD")
  } else {
    return moment(date).format("MM/DD/YY")
  }
}
export function debounce(fn, time) {
  let timeoutId
  return wrapper
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}
export function readFile(file, readType) {
  readType = readType || 'readAsDataURL';
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function (readerResult) {
      resolve(readerResult.target.result);
    }
    reader.onerror = function (e) {
      reject(e);
    };

    reader[readType](file);
  })
}

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
//Base 64 to arraybuffer
export function convertDataURIToBinary(dataURI) {
  let BASE64_MARKER = ';base64,';
  let base64Index = 0;
  let base64 = "";
  if (dataURI) {
    if (~dataURI.indexOf(BASE64_MARKER)) {
      base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      base64 = dataURI.substring(base64Index);
    } else base64 = dataURI;
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
export function copy(o, fast) {
  var output, v, key;
  if (fast) return JSON.parse(JSON.stringify(o));
  output = Array.isArray(o) ? [] : {};
  for (key in o) {
    v = o[key];
    output[key] = (typeof v === "object" && v) ? copy(v) : v;
  }
  return output;
}
export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    (!collection) ? map.set(key, [item]) : collection.push(item);
  });
  return map
}
export function compareKeys(a, b) {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}
export function MarkupFormatter(options) {
  let className = options.className || 'myClassName'
  let markup = options.markup || '';
  let result = `
import React, { Component } from 'react';

class ${className} extends Component {
  constructor(props){
    super(props)
  }
  render(){
    return(
${markup}
    );
  }
}
export default ${className}
`
  return result;
}
export function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {}
      )
    : {}
};
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
export function getPath(obj, key, val, path) {
  path = path || "";
  let fullPath = "";
  for (var b in obj) {
    if (b === key && obj[b] === val) {
      return path;
    } else if ((obj[b] && typeof obj[b] === "object" && obj[b].hasOwnProperty(key)) || Array.isArray(obj[b])) {
      let newPath = path.length ? `${path}.${b}` : `${b}`;
      fullPath = getPath(obj[b], key, val, newPath) || fullPath;
    }
  }
  return fullPath;
}
export function resolvePath(path, obj) {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null
  }, obj)
}
export function isMobile() {
  var check = false;
  (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
export function getAllIndexes(arr, val) {
  var indexes = [], i;
  for (i = 0; i < arr.length; i++)
    if (arr[i] === val)
      indexes.push(i);
  return indexes;
}
export function ArrayMove(arr, from, to) {
  arr.splice(to, 0, arr.splice(from, 1)[0])
}
export function upload(url, formData, responseType, uploadProgressCallback, downloadProgressCallback) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.responseType = responseType || 'blob';
    xhr.onload = function () {
      if (xhr.status === 200) {
        if (xhr.responseType === 'blob') {
          let reader = new FileReader();
          reader.onload = function (evt) {
            resolve(evt.target.result);
          }
          reader.readAsArrayBuffer(xhr.response);
        } else resolve(JSON.parse(xhr.response));
      } else {
        reject(xhr);
      }
    }
    xhr.onerror = function (err) {
      reject(xhr, err);
    }
    xhr.upload.onprogress = function (evt) {
      if (uploadProgressCallback) uploadProgressCallback(evt);
    }
    xhr.onprogress = function (evt) {
      if (downloadProgressCallback) downloadProgressCallback(evt);
    }
    xhr.send(formData);
  })
}
export function download(url, params, responseType, progressCallback) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = responseType || 'blob';
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onload = function () {
      if (xhr.status === 200) {
        if (xhr.responseType === 'blob') {
          let reader = new FileReader();
          reader.onload = function (evt) {
            resolve(evt.target.result);
          }
          reader.readAsArrayBuffer(xhr.response);
        } else resolve(JSON.parse(xhr.response));
      } else {
        reject(xhr);
      }
    }
    xhr.onprogress = function (evt) {
      if (progressCallback) progressCallback(evt);
    }
    xhr.onerror = function (err) {
      reject(xhr, err);
    }
    xhr.send(JSON.stringify(params));
  })
}
export function image_download(url, readAsArrayBuffer, progressCallback) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", url, true);
    xhr.onload = function () {
      if (xhr.status === 200) {
        let reader = new FileReader();
        reader.onload = function (evt) {
          resolve(evt.target.result);
        }
        readAsArrayBuffer ? reader.readAsArrayBuffer(xhr.response) : reader.readAsDataURL(xhr.response);
      } else {
        reject();
      }
    }
    xhr.onerror = function (err) {
      reject(err);
    }
    xhr.onprogress = function (evt) {
      if (progressCallback) progressCallback(evt);
    }
    xhr.send();
  })
}
export function determineTextColor(color) {
  if (color) {
    let rgb = {};
    if (~color.indexOf('#')) {
      //#000000
      rgb = hexToRgb(color);
    } else {
      //rgb(0,0,0)
      const rgbArr = color.replace(/[^\d,]/g, '').split(',');
      rgb = { r: rgbArr[0], g: rgbArr[1], b: rgbArr[2] }
    }
    return (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? 'black-text' : 'white-text';
  }
  return 'black-text';
}

export function rgbToHex(r, g, b) {
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function pick(o, ...props) {
  return Object.assign({}, ...props.map(prop => ({ [prop]: o[prop] })));
}

export function formatBytes(a, b) { if (0 === a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f] }

export function changeState(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) };
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, "_path") && has(updateArg, "_value")) {
      const { _path, _value } = updateArg;
      let newState = { ...state };
      newState = produce(newState, draft => {
        set(draft, _path, _value);
      });
      return newState;
    } else {
      return { ...state, ...updateArg };
    }
  }
  if (Array.isArray(updateArg)) {
    let newState = { ...state };
    for (let i = 0; i < updateArg.length; i++) {
      if (has(updateArg[i], "_path") && has(updateArg[i], "_value")) {
        const { _path, _value } = updateArg[i];
        newState = produce(newState, draft => {
          set(draft, _path, _value);
        });
      }

    }
    return newState
  }
}
