import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import './Navbar.css';
import EditWrapper from '../EditWrapper/EditWrapper';
import {Bars} from 'react-loader-spinner'
import { base_url} from '../../data/db';
import LinkDuo from '../LinkDuo/LinkDuo';
import SideMenu from './SideMenu';
import Drawer from '@material-ui/core/Drawer'
import AZMenu from './AZMenu';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actionCreators as dbCreators } from '../../afw/store/db';
import { bindActionCreators } from 'redux';
import DownShiftSearch from './DownShiftSearch';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    boxSizing: 'border-box',
  },
  flex: {
    flexGrow: 1,
    borderLeft:'1px solid black',
    paddingLeft: '10px'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  barStyle: {
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.01), 0px 1px 10px 0px rgba(0,0,0,0.01)',
    minHeight: '65px'
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    maxHeight: '60px',
    marginRight: '10px',
  },
  hideMenu: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  hideSm:{
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  active: {
    background: 'rgba(51,51,51, 0.9)'
  },
  menuBtn: {
    height: '65px', 
    padding: '0 10px',
    transition: 'height 1s ease,background-color 0.2s ease'
  },
  menuBtnText:{
    fontSize: '0.775rem', 
    lineHeight: '65px', 
    verticalAlign: 'middle'
  },
  smallToolbarTransition:{
    transition: 'ease height 300ms, ease max-height 300ms, ease min-height 300ms'
  },
  menuBorderRadius:{
    borderRadius: '0px'
  },
  hideOverflow: {
    overflow: 'hidden',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pointer: {
    cursor: 'pointer'
  }
});
/*
  JSON props schema
  datasource: array
  image: string
*/
class Navbar extends Component {
  state = {
    auth: false,
    showSearch: false,
    barStyle: this.props.classes.barStyle,
    toolbarColor: undefined,
    height: '65px',
    showLogo: false,
    showDrawer: false,
    showAZ: false
  };
  componentDidMount(){
    window.addEventListener('scroll', this.handleHideLogo);
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleHideLogo);
  }
  componentDidUpdate(prevProps){
    if(prevProps.location.pathname !== this.props.location.pathname){
      this.setState({showAZ: false, showDrawer: false})
    }
  }
  handleHideLogo=()=>{
    const { showLogo } = this.state
    window.scrollY > 135 
      ? !showLogo && this.setState({ showLogo: true }) 
      : showLogo && this.setState({ showLogo: false });
    this.prev = window.scrollY;
  }
  handleShowSearch = ()=>{
    this.setState({showSearch: true})
  }
  handleToggleDrawer=()=>{
    this.setState({showDrawer: !this.state.showDrawer})
  }
  handleToggleAZ=()=>{
    this.setState({showAZ: !this.state.showAZ})
  }
  handleGetButton=(type, first, next)=>{
    return(
      <React.Fragment key={first.nav_id}>
        <ButtonBase className={`dropdown-cell ${type}`} component={LinkDuo} target={first.target ? "_blank" : undefined} to={first.nav_link} focusRipple>
          <div>
            <Typography variant="body2" className="dropdown-sub-item white-text">{first.nav_title}</Typography>
          </div>
        </ButtonBase>
        {
          next && (
            <ButtonBase className={`dropdown-cell ${type}`} target={next.target ? "_blank" : undefined} component={LinkDuo} to={next.nav_link} focusRipple>
              <div>
                <Typography variant="body2" className="dropdown-sub-item white-text">{next.nav_title}</Typography>
              </div>
            </ButtonBase>
          )
        }
      </React.Fragment>

    )
  }
  handleGetReducedItems=(items)=>{
    if(items.length){
      let result = items.sort((a,b)=>{
        var textA = a.order;
        var textB = b.order;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }).reduce((acc, val, idx, arr)=>{
        if(idx%2 === 0 && arr.length > 4){
          acc[idx] = (
            <div key={idx} className="dropdown-row">
              {this.handleGetButton('dual', arr[idx], arr[idx+1])}
            </div>
          )
        } else if(arr.length <= 4) acc.push(this.handleGetButton('single', arr[idx]))
        return acc;
      },[])
      return result;
    }
  }
  render() {
    let { classes, dataSource, editMode, showEdit, style, logo} = this.props;
    const {showAZ} = this.state;
    dataSource = dataSource.sort((a,b)=>{
      var textA = a.order;
      var textB = b.order;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    const {height, showDrawer} = this.state;
    const loadFill = Array.from(new Array(6),(_,index)=>index);
    return (
      <div className={classes.root} style={style}>
        <EditWrapper editor="Navigation Bar" onCopy={this.props.editMode.onCopy} editEnabled={editMode.editEnabled} onEdit={()=>showEdit(0, 'NavBar')} editTooltip="Edit Toolbar">
          <Drawer variant="temporary" open={showDrawer} onClose={this.handleToggleDrawer} ModalProps={{style: {zIndex: 1500}, keepMounted: true}}>
            <SideMenu navData={dataSource}/>
          </Drawer>
          <Drawer anchor="right" variant="temporary" open={showAZ} onClose={this.handleToggleAZ} ModalProps={{style: {zIndex: 1500}, keepMounted: true}}>
            <AZMenu navData={dataSource}/>
          </Drawer>
          <AppBar position="static" color={"default"} className={`${this.state.barStyle}`}>
            <Toolbar>
              <IconButton className={`${classes.menuButton}`} color="inherit" aria-label="Menu" onClick={this.handleToggleDrawer}>
                <MenuIcon />
              </IconButton>
              <EditWrapper editEnabled={editMode.editEnabled} onEdit={()=>showEdit(1, 'NavBar')} editTooltip="Edit Logo">
                <div>
                  {
                    logo 
                      ? (
                        <ButtonBase component={LinkDuo} to={'/'} className={`${classes.hideOverflow} ${classes.pointer}`}>
                          <img alt="MDOT" className={`${classes.logo}`} style={{verticalAlign: 'middle'}} src={`${base_url}${logo}`}/> 
                        </ButtonBase>
                      )
                      : <div className={classes.hideSm}><Bars color="#333" height="150" width="60" /></div>
                  }
                  
                </div>
              </EditWrapper>
              <div className={`${classes.flex} ${classes.hideSm}`}></div>
              <div className={classes.hideMd}>
                {
                  dataSource.length ?
                    dataSource.map((o, i)=>(
                        <React.Fragment key={o.nav_id}>
                          <div key={o.nav_id} className={`header-btn-hover ${classes.menuBtn}`} style={{height}}>
                            <ButtonBase component={LinkDuo} target={o.target ? "_blank" : undefined} to={o.nav_link} focusRipple className={`${o.active ? classes.active : ''}`}>
                              <Typography className={`${o.active ? 'white-text' : ''} ${classes.menuBtnText}`} style={{lineHeight: height}} variant="button" gutterBottom>{o.nav_title}</Typography>
                            </ButtonBase>
                            <div className="dropdown">
                              {this.handleGetReducedItems(o.SR_t_navitem_PARENT_ID)}
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )
                  : loadFill.map((o,i)=><div key={i} style={{background: '#ddd', margin: '0 2px', height: '35px', width: '100px', display: 'inline-block'}}></div>)
                }
              </div>
              <DownShiftSearch />
              <div className={classes.hideSm}>
                <Button color="inherit" onClick={this.handleToggleAZ}>A-Z</Button>
              </div>
            </Toolbar>
          </AppBar>
        </EditWrapper>
      </div>
    );
  }
 }
Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};
Navbar.defaultProps={
  dataSource: [],
  editMode: {
    editEnabled: false,
    removeEnabled: false
  },
  showEdit: ()=>{}
}

export default connect(
  state => ({db: state.db}),
  dispatch => bindActionCreators({...dbCreators}, dispatch)
)(withStyles(styles)(withRouter(Navbar)));