//import {userstore} from '../data/userstore';
//Layout Store editer
const titleUpdate = 'TITLE_UPDATE';
const openSideNav = "SIDE_NAV_OPEN";
const navChange = "NAV_CHANGE";
const initialState = { title: 'Home', mobileOpen: false, showExtraMenu: false, navData: [], flatNavData: []};

export const actionCreators = {
  //title update action creator
  titleUpdate: (newTitle) => ({ type: titleUpdate, newTitle: newTitle }),
  openSideNav: (open) => ({ type: openSideNav, open: open }),
  navDataChange: (navData, keepItems, flatData) => ({ type: navChange, data: navData, keepItems: keepItems, flatData: flatData })
};

export const reducer = (state, action) => {
  state = state || initialState;
  //The title Update function that sets a new title
  if (action.type === titleUpdate) {
    return {
      ...state,
      title: action.newTitle
    };
  }

  if (action.type === openSideNav) {
    return {
      ...state,
      mobileOpen: action.open
    };
  }

  if (action.type === navChange) {
    return {
      ...state,
      navData: action.data,
      flatNavData: action.flatData,
      toolbarItems: action.keepItems ? state.toolbarItems : []
    };
  }
  return state;
};