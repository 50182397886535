/*
 * File: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp\src\components\NavBar\DownShiftFetch.js
 * Project: c:\Users\twalton\Documents\Projects\goMDOT\goMDOT\ClientApp
 * Created Date: Thursday September 12th 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday June 15th 2020 9:46:50 am
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React from 'react';
import { connect } from 'react-redux';
import { actionCreators as dbCreators } from '../../afw/store/db';
import { bindActionCreators } from 'redux';
import {debounce} from '../../afw/js/common';
import stringSimilarity from 'string-similarity';
import {historyStore} from './DownShiftSearch'
import {stop_words} from '../../data/db';

class DownShiftFetch extends React.PureComponent {
  state = {loading: false, error: null, results: []}
  fetch = debounce(async () => {
    const {db, searchValue} = this.props;
    const searchFields = ['name', 'tags', 'description'];
    const multipleSearch = searchValue ? searchValue.split(" ") : [];
    const filter = searchFields.reduce((total, currentValue, index)=>{
      if(index !== 0) total.push('or');
      total.push([currentValue, 'contains', searchValue]);
      multipleSearch.forEach((item, multipleIndex)=>{
        if(item.length > 1 && multipleSearch.length > 1 && !Boolean(~stop_words.indexOf(item))){
          total.push('or');
          total.push([currentValue, 'contains', item]);
        }
      })
      return total;
    }, [])
    try{
      const results = searchValue === null ? [] : await db.v_search_api.load({
        filter, 
        take: 10, 
        sort: {getter: 'priority', desc: false},
        select: ['name', 'description', 'link', 'result_type', 'icon']
      });
      results.sort((a,b)=>{
        const ans = stringSimilarity.compareTwoStrings(a.name, searchValue) + stringSimilarity.compareTwoStrings(a.description, searchValue);
        const bns = stringSimilarity.compareTwoStrings(b.name, searchValue) + stringSimilarity.compareTwoStrings(b.description, searchValue);
        return (bns-ans);
      }).reduce((unique, o)=>{
        if(!unique.some(obj => obj.name === o.name || obj.link === o.link)) unique.push(o);
        return unique;
      }, []);
      const historyResult = await historyStore.load({take: 3, filter: searchValue ? filter : undefined});
      historyResult.sort((a,b)=>b.added-a.added);
      const fullResults = historyResult.concat(results);
      this.props.onLoaded({searchValue, fullResults});
      this.setState({loading: false, results: fullResults})
    }catch(error){
      this.setState({loading: false, error})
    }
  }, 300)
  prepareFetch=(e)=>{
    this.setState({loading: true})
    this.fetch()
  }
  componentDidMount() { 
    if(!this.props.preFilteredResults) this.prepareFetch();
    else this.setState({loading: false, results: this.props.preFilteredResults})
  }
  componentDidUpdate(prevProps) {
    if ( prevProps.searchValue !== this.props.searchValue ) this.prepareFetch();
  }
  render() {
    return this.props.render(this.state)
  }
}

export default connect(
  state => ({db: state.db}),
  dispatch => bindActionCreators({...dbCreators}, dispatch),
  null,
  {forwardRef: true}
)(DownShiftFetch)