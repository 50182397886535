import EntityModel from './baseModel';
import moment from 'moment';
import overloads from './overloadEntityModel';

//Set Default Property Values
function parseObjectData(obj){
  let result = {};
  //C# Types to set defaults
  const TypeEnum = {
      'Int32': 0,
      'Int64': 0,
      'String': null,
      'DateTime': moment(),
      'Boolean': false,
      'Decimal': 0.00,
      'ICollection`1': [],
      'Nullable`1': null
  }
  for(let prop in obj){
    const type = TypeEnum[obj[prop]];
    result[prop] = type !== undefined ? type : {};
  }
  return result;
}
//Checks for empty object
function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
export default function parseObjectModel(OM){
  let classes = {}
  let tables = "";
  for(let prop in OM){
    //Set Default Property Values
    const props = parseObjectData(OM[prop]);
    const nameIt = (name, OMProps) => ({[name] : class extends EntityModel {
      displayName = name
      constructor(props) {
        super(props);
        //Merge the Entity Model Props with the provided props
        for(let prop in OMProps) this[prop] = OMProps[prop];
        this._mergeDefaultProps(props);
      }
      imports=()=>{
        const self = this;
        for(let prop in self){
          const matches = prop.match(tables);
          //If property is a table, or contains the table name (ex: SR_t_table_UPDATE_DATE)
          if(classes[prop] || matches){
            const classProp = matches ? matches[0] : prop;
            //If navigation property 1-Many and also has value
            if(Array.isArray(self[prop]) && self[prop].length){
              self[prop] = self[prop].map(o=>new classes[classProp](o))
              //If navigation property 1-1 and also has value
            }else if(!Array.isArray(self[prop]) && !isEmpty(self[prop])){
              self[prop] = new classes[classProp](self[prop]);
            }
          }
          //Hard-coded naming convention audit trails, remove if not implemented in db
          if(prop === 'create_user'){
            self[prop] = self[prop].length ? self[prop] : (window.user && window.user.account_id)
          }
        }
        //User defined overloads
        if(overloads[this.displayName] && overloads[this.displayName].imports){
          overloads[this.displayName].imports(this);
        }
      }
      exports=(self, displayName)=>{
        let exportObj = {};
        for(let prop in self){
          const matches = prop.match(tables);
          if(classes[prop] || matches){
            //If navigation property 1-Many and also has value
            if(Array.isArray(self[prop]) && self[prop][0] && self[prop][0].toJS){
              exportObj = {...exportObj, ...{[prop]: self[prop].map((o)=>o.toJS())}}
            //If navigation property 1-1 and also has value
            }else if(self[prop] && self[prop].toJS){
              exportObj = {...exportObj, ...{[prop]: self[prop].toJS()}}
            //Set to undefined so EF Core will not try to upsert
            } else if(Array.isArray(self[prop])){
              self[prop] = [];
            }else self[prop] = null;
          }
          //Hard-coded naming convention audit trails, remove if not implemented in db
          if(prop === 'update_user'){
            exportObj = {...exportObj, ...{update_user: window.user.account_id}}
          }
          if(prop === 'update_date'){
            exportObj = {...exportObj, ...{update_date: moment().format()}}
          }
        }
        //User defined overloads
        if(overloads[displayName] && overloads[displayName].exports){
          exportObj = {...exportObj, ...overloads[displayName].exports(self)}
        }
        return exportObj
      }
    }}[name]);
    classes[prop] = nameIt(prop, props);
  }
  tables = new RegExp(Object.keys(classes).sort((a, b)=>b.length - a.length).join("|"), "g");
  return classes;
}