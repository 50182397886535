//These are required for many system components, only use if you are positive
//that those components will not use these functions.
import 'core-js/fn/array/fill';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/from';
import "core-js/fn/symbol/iterator.js";
import "core-js/es6/symbol.js";
import 'es6-shim';
//import 'custom-event-polyfill';
//ASP.NET String.format equivalent
//https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
if (!String.format) {
  String.format = function(format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] !== 'undefined'
        ? args[number] 
        : match
      ;
    });
  };
}